import React from 'react';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import { useModal } from '@hooks/useModal';
import Button from '@components/UIkit/Button';
import { PaymentMethod } from '@core/payment/service';

interface IModalComfirm {
  onSuccess: (method: PaymentMethod) => void;
}

const { Paragraph } = Typography;

const ModalChoosePaymentMethod: React.FC<IModalComfirm> = ({ onSuccess }) => {
  const { closeModal } = useModal();

  const handleSuccess = (method: PaymentMethod) => {
    onSuccess(method);
    closeModal();
  };

  return (
    <Space direction="vertical" gap={40}>
      <Paragraph>Choose payment method</Paragraph>

      <Space gap={20}>
        <Button
          id="button-modal-choose-payment-method-monobank"
          onClick={() => handleSuccess(PaymentMethod.Mono)}
          type="default"
        >
          MonoPay
        </Button>

        <Button
          id="button-modal-choose-payment-method-whitepay"
          onClick={() => handleSuccess(PaymentMethod.Whitepay)}
          type="default"
        >
          WhitePay
        </Button>
      </Space>

      <Space gap={20} justify="start">
        <Button
          id="button-modal-confirm-cancel"
          onClick={closeModal}
          type="danger"
        >
          Cancel
        </Button>
      </Space>
    </Space>
  );
};

export default React.memo(ModalChoosePaymentMethod);
