export const toastMessages = {
  link_copied: 'Link copied',
  link_not_found: 'Link not found or does not exist',
  link_created_successfully: 'Link created successfully',
  link_updated_successfully: 'Link updated successfully',
  link_deleted_successfully: 'Link deleted successfully',
  qrcode_created_successfully: 'QR Code created successfully',
  qrcode_updated_successfully: 'QR Code updated successfully',
  qrcode_deleted_successfully: 'QR Code deleted successfully',
  subscription_completed_successfully: 'Subscription completed successfully',
  new_subscription_successfully_processed:
    'New subscription successfully processed',
  new_subscription_failed: 'New subscription processing failed',
  file_uploaded_successfully: 'File uploaded successfully',
};

export const isProd = `${window.location.origin}/` === 'https://murl.gg/';

export const appVersion = '1.0.4';
