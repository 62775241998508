import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import configSelector from '@configSelector';
import { IPlan, plansData } from '@common/types';
import { errorHandler, formatDateTime, successHandler } from '@common/helpers';
import { toastMessages } from '@common/constants';
import Headline from '@components/Headline';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import ShortenPlan from '@components/ShortenPlan';
import styles from './styles.module.scss';

interface IPlans {
  tier: string;
  actual_to: number;
}

const { Paragraph, Text } = Typography;

const PlansContainer: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { tier, actual_to } = useSelector(configSelector.mySubscription);

  useEffect(() => {
    const paymentStatus = searchParams.get('paymentstatus');

    if (paymentStatus === 'success') {
      successHandler(toastMessages.new_subscription_successfully_processed);
    }

    if (paymentStatus === 'failure') {
      errorHandler({
        message: toastMessages.new_subscription_failed,
        code: 400,
      });
    }
  }, [searchParams]);

  return <Plans tier={tier} actual_to={actual_to} />;
};

const Plans: React.FC<IPlans> = ({ tier, actual_to }) => {
  const MyTier: IPlan | undefined = plansData.find((p) => p.id === tier);
  const MyTriggersLimit = useSelector(configSelector.myTriggersLimit);

  return (
    <Space direction="vertical" gap={60} className={styles.plans}>
      <Space direction="vertical" gap={40} fullWidth>
        <Headline title="Plans" align="left" />

        <Space
          direction="vertical"
          gap={10}
          className={styles.myPlan}
          fullWidth
        >
          <Text size={2} className={styles.available}>
            Available to:{' '}
            <Text size={3} font="Inter" className={styles.value}>
              {actual_to ? formatDateTime(actual_to) : 'Endless'}
            </Text>
          </Text>

          <Paragraph size={2} className={styles.name}>
            {MyTier?.name}
          </Paragraph>

          <Text size={2} className={styles.description}>
            {MyTier?.description}
          </Text>

          <Text size={2} className={styles.limits}>
            Limits:{' '}
            <Text font="Inter" size={2}>
              {MyTriggersLimit}/{MyTier?.triggers}
            </Text>
          </Text>
        </Space>
      </Space>

      <Space direction="vertical" gap={40}>
        <Headline
          title="Change of tariff plan"
          align="left"
          description="When renewing the current subscription, any unused days will be
            carried over to the new period. If switching to a different
            subscription, unused days will be forfeited."
        />

        <Space direction="vertical" align="normal" gap={20} fullWidth>
          {plansData.map(
            (p: IPlan) =>
              p && (
                <ShortenPlan
                  isCurrentTier={MyTier ? p.id === MyTier.id : false}
                  key={p.name}
                  data={p}
                  myTriggersLimit={MyTriggersLimit}
                />
              )
          )}
        </Space>
      </Space>
    </Space>
  );
};

export default React.memo(PlansContainer);
